import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "./assets/styles/main.css"
import "./assets/styles/common.scss"
import { currency } from './utils/sg'
import VueClipboard from 'vue-clipboard2'
import Toasted from 'vue-toasted';
import { track } from './api/track'
Vue.config.productionTip = false
import './assets/styles/boder.css'
//import './assets/styles/reset.css'
import './rem'
import ucHeader from '@/components/ucHeader'
Vue.component('ucHeader', ucHeader)
import ucContact from '@/components/ucContact'
Vue.component('ucContact', ucContact)
import ucCompany from '@/components/ucCompany'
Vue.component('ucCompany', ucCompany)

import { Field, Form, Button, Notify, Picker, Popup, Row, Col, Image as VanImage, Toast } from 'vant';
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Notify);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Col);
Vue.use(Row);
Vue.use(VanImage);
Vue.use(Toast);
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)
import VueClipboards from 'v-clipboard';
Vue.use(VueClipboards);
// const url = new URL(window.location.href);
// console.log('11111', url.origin);
// console.log('aaaaa', url.search);
// const newUrl = `${url.origin}/#/${url.search}`
// console.log(2222, newUrl);
console.log('地址', window.location.search);

Vue.filter("currency", currency)
Vue.use(VueClipboard)
Vue.use(Toasted)

Vue.prototype.markTrack = function (no, status) {
	if (!no) {
		return
	}
	if (!status) {
		return
	}
	track(no, status).then(response => {
		console.log(response)
	})
}
if (window.location.hash) {
	const url = new URL(window.location.href);
	//console.log(1111, url.hash);
	const newUrl = `${url.origin}${url.pathname}#${url.search}${url.hash.slice(1)}`
	//console.log('url', newUrl);
	const newUrl2 = newUrl.slice(0, -1)
	//console.log('newUrl2', newUrl2);
	window.location.replace(newUrl);
}

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')





